// Only load this on the registration page
if (window.location.pathname == '/') {
    function getTimezone() {
        // Get Timezone of current user as a string in GMT format
        var currentTime = new Date();
        var timezone = (currentTime.getTimezoneOffset() / 60) * -1;
        var gmt = 'GMT';
        if (timezone !== 0) {
            gmt += timezone > 0 ? '+' : '';
            gmt += timezone;
        } else if (timezone == 0) {
            gmt += '+0';
        }
        return gmt;
    }

    function getSummitDate() {
        return new Promise(function (resolve, reject) {
            const params = new URLSearchParams({ timezone: getTimezone() });

            var xhr = new XMLHttpRequest();
            xhr.open(
                'GET',
                window.location.origin + '/.netlify/functions/get-summit-times/?' +
                    params.toString()
            );
            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onload = function () {
                if (this.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr.statusText);
                }
            };
            xhr.onerror = function () {
                reject(xhr.statusText);
            };

            xhr.send();
        });
    }

    async function populateDates() {
        // Get the select fields to populate
        var dateSelector = document.querySelectorAll(
            'select[name=schedule]'
        );
        var summitData = JSON.parse(await getSummitDate());

        if (summitData.status == 'success') {
            dateSelector.forEach(function (select) {
                select.querySelectorAll('option')[1].remove();
                // Loop through the dates and create the options in each date selector
                summitData.schedules.forEach(function (date) {
                    var option = document.createElement('option');
                    option.text =
                        date.date + ' (' + summitData.timezone + ')';
                    option.value = date.id;
                    select.add(option);
                });
            });
        }
    }

    function registerForSummit() {
        // grab country coded phone number from form for ajax submit
        var phoneInput = document.querySelector('[name="phone_country_code"]');
        if(phoneInput){
            phoneInput.value = '+'+window.iti.getSelectedCountryData().dialCode;
        }

        var form = document.querySelector('form');
        var formData = new FormData(form);
        var data = [...formData.entries()];
        data.push(['timezone', getTimezone()]);

        var select = document.querySelector('select');
        var selectedDate = select.options[select.selectedIndex].text;
        var selectedDate = btoa(selectedDate);


        var queryString = data
            .map(
                (x) =>
                    `${encodeURIComponent(x[0])}=${encodeURIComponent(
                        x[1]
                    )}`
            )
            .join('&');

        var xhr = new XMLHttpRequest();
        xhr.open(
            'POST',
            window.location.origin + '/.netlify/functions/register-for-summit?' +
                queryString
        );
        xhr.setRequestHeader(
            'Content-Type',
            'application/x-www-form-urlencoded'
        );
        xhr.onload = function () {
            if (this.status === 200) {
                var response = JSON.parse(xhr.response);
                if (response.data.status == 'success') {
                    dataLayer.push({
                        event: 'gtm.formSubmission',
                        form: 'registration',
                    });
                    if(response.data.user.schedule == "15" || response.data.user.schedule == "16") {
                        window.location.href = response.data.user.live_room_url;
                    } else {
                        window.location.href =
                        'https://summit.selllikecrazybook.com/dont-wait/?da=' +
                        selectedDate +
                        '&live=' +
                        btoa(response.data.user.live_room_url);
                    }
                
                } else {
                    alert(
                        'We were unable to register you for the Summit. Please try again later.'
                    );
                    console.error(response);
                }
            } else {
                alert(
                    'We were unable to register you for the Summit. Please try again later.'
                );
                console.error(response);
            }
        };
        xhr.onerror = function () {
            alert(xhr.statusText);
        };
        xhr.send(JSON.stringify(formData));
    }

    window.contentLoaded(window, function (e) {
        populateDates();

        // Add form handler to the form
        document
            .querySelector('form')
            .addEventListener('submit', function (e) {
                e.preventDefault();
                registerForSummit();
            });
    });
}