/**
 * To use the ebook modal, add the button and modal as the next sibling.
 * Add the class .js-ebook-modal-trigger to the button trigger, and the class .js-ebook-modal to the modal window
 */


if(navigator.platform.indexOf('Win') > -1){
    document.body.classList.add('js-modal-windows');
}


// Toggle the modal window class
window.toggleModal = function(modal) {
    // Set the modal parent element to active
    modal.classList.toggle('active');
    document.body.classList.toggle('js-modal-active');
}

window.initModalTriggers = function(){

    // Get all of the trigger buttons
    var modalButtons = document.querySelectorAll('[data-js-modal-trigger]'),
          modalOverlay = document.querySelectorAll('[data-js-modal-id]');


    // Add click event to the buttons
    modalButtons.forEach(function(selected,index) {
        selected.addEventListener('click',function(el) {
            el.preventDefault();

            // On click, find and activate the modal with the matching id to the trigger data attribute
            let targetId = el.target.dataset.jsModalTrigger;
            let targetModal = document.querySelector('[data-js-modal-id='+targetId);

            if(selected.classList.contains('siteswitcher__cancel')){
                var modalClosed = new CustomEvent("modalClosed", { detail: targetId });
                document.dispatchEvent(modalClosed);
            }
            
            toggleModal(targetModal);
        })
    });

    // Add click event to the modal bg to close
    modalOverlay.forEach(function(overlay,index) {
        overlay.addEventListener('click', function(el) {
            if(el.target.classList.contains('js-modal')){
                toggleModal(el.target);
                var modalClosed = new CustomEvent("modalClosed", { detail: overlay.dataset.jsModalId });
                document.dispatchEvent(modalClosed);

                if(typeof window.mainWistiaVideo !== "undefined"){
                    window.mainWistiaVideo.play();
                }
            }
        });

        overlay.querySelectorAll('.js-close').forEach(function(closeButton,index) {
            closeButton.addEventListener('click',function(){
                toggleModal(overlay);
                var modalClosed = new CustomEvent("modalClosed", { detail: overlay.dataset.jsModalId });
                document.dispatchEvent(modalClosed);
            })
        });
    });
}

function initExitIntent(){
    var exitHappened = 0;
    var eiModal = document.querySelector('.exitintent');

    if(eiModal == null){
        return;
    }

    var eiProgressBar = document.querySelector('.c-progress__bar');

    if(eiModal){
        document.addEventListener("mouseout", function(evt){
            if ((evt.toElement === null && evt.relatedTarget === null || (typeof evt.toElement == "undefined" && evt.relatedTarget === null)) && exitHappened < 1) {
                if(eiProgressBar){
                    eiProgressBar.classList.add('c-loading');
                }

                if(typeof window.mainWistiaVideo != "undefined"){
                    window.mainWistiaVideo.pause();
                }

                if(!document.body.classList.contains('js-modal-active')){
                    exitHappened = 1;
                    toggleModal(eiModal);
                }
            }
        });
    }
}

window.contentLoaded(window, function (e) {
    initModalTriggers(false);
    initExitIntent();
});

