(function (root, factory) {
	if (typeof define === "function" && define.amd) {
		// AMD. Register as an anonymous module.
		define([], factory);
	} else {
		// Browser globals
		root.geoFields = factory(root);
	}
})(typeof self !== "undefined" ? self : this, function () {
	'use strict';

window.iti = false;

// Got _countries from here: https://github.com/meeDamian/country-emoji
var _initTel = function() {
	// Tel number select: check if there are any tel inputs on the page, if so, replace them with the fancy ones
	// var telInputs = document.querySelectorAll('input[type="tel"]');
	// for (var i = 0; i < telInputs.length; i++) {
	// 	_createTelField(telInputs[i]);
	// }
	const telInput = document.querySelector('[name="phone"]');
	
	if(telInput){
		window.iti = intlTelInput(telInput, {
		    preferredCountries: ['au','us','ca','gb','nz'],
		    initialCountry: localStorage._kkcountry,
		    hiddenInput: 'phone_country_code',
		    autoPlaceholder: 'aggressive',
		    customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
				return "Phone Number... " + selectedCountryPlaceholder;
			},
		    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.0/js/utils.min.js'
		});
	}
}

var _initIP = function(){
	var ipField = document.querySelector('[name="inf_custom_IPAddress"]');

	if(ipField){
		ipField.value = localStorage.getItem('_kkip');
	}
}

var _init = function() {
	// check if there is not a country code in session storage already get the country
	if (!localStorage._kkcountry || !localStorage._kkip) {
		// If there is no session variable set, run the get country function
		_getCountry();
	} else {
		// If there is already a country set, set up the tel inputs and country fields
		_initTel();
		_initIP();
	}
}

/**
 * Get user's country/state/IP
 */
// https://pro.ip-api.com/json/?key=xdE0u3qK4coQ9a7&fields=status,message,country,countryCode,query
var _getCountry = function() {

	function requestError() {
		initFields();
	}	
	
	function initFields() {
		_initTel();
		_initIP();
	}

	function request() {
		var url = 'https://pro.ip-api.com/json/?key=xdE0u3qK4coQ9a7&fields=status,message,countryCode,query';
		var xhr = new XMLHttpRequest();
		xhr.onerror = requestError;
		xhr.onreadystatechange = function() {
			if (this.readyState === 4) {
				if(this.status === 200) {
					var response = JSON.parse(this.responseText);
					// If the response fails, do nothing
					if(response.status == 'success') {
						// On success, save to localStorage and initialise fields
						localStorage.setItem('_kkcountry', response.countryCode);
						localStorage.setItem('_kkip', response.query);
						initFields();
					} else {
						// On fail, go to error
						requestError();
					}
					
				} else {
					requestError();
				}
			}
		};
		xhr.open('GET', url);
		xhr.send();
	}
	request();

}


// when the page has loaded, run our init function
window.contentLoaded(window, _init);


// Just return a value to define the module export.
// This example returns an object, but the module
// can return a function as the exported value.
return {
	init: _init
};

});


// geoFields.init();





