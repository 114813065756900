// If we want 15 minute rotations for the summit, set this to true.
const everGreen = true;

window.contentLoaded(window, function (e) {
    countdownTimer(); 
    getCurrentDay();
    getCurrentMonth();
    getCurrentYear();
    getDayofMonth();
    getSummitDayOfWeek();
    getSummitDay();
    getSummitMonth();
    getSummitYear();
}); 

function countdownTimer(timeOfEvent){
    var summitDate = nextSummitDate();
    var timeOfEvent = summitDate.getTime();
    var buttonCounters = document.querySelectorAll('.timeleft');
    var shortCounter = document.querySelectorAll('.short-counter');

    var interval = setInterval(function () {
        var currentTime = Date.now();
        var timeLeft = timeOfEvent - currentTime;

        if(timeLeft < 1) {
             clearInterval(interval);
             return;
        }

        var secsLeft = timeLeft / 1000;

        var daysLeft = secsLeft / 60 / 60 / 24;
        var hoursLeft = (daysLeft - Math.floor(daysLeft)) * 24;
        var minsLeft = (hoursLeft - Math.floor(hoursLeft)) * 60;
        var secsLeft = (minsLeft - Math.floor(minsLeft)) * 60;

        daysLeft = Math.floor(daysLeft);
        hoursLeft = Math.floor(hoursLeft);
        minsLeft = Math.floor(minsLeft);
        secsLeft = Math.floor(secsLeft);

        var outputDate = '';

        outputDate += daysLeft + ' Day' + (daysLeft !== 1 ? 's ' : ' ');
        outputDate += hoursLeft + ' Hour' + (hoursLeft !== 1 ? 's ' : ' ');
        outputDate += minsLeft + ' Minute' + (minsLeft !== 1 ? 's ' : '');
        outputDate += secsLeft + ' Second' + (secsLeft !== 1 ? 's ' : '');

        buttonCounters.forEach(function (el, index) {
            el.innerHTML = outputDate;
        });

        var shortOutputDate = '';
        var shortMinuteDate = '';

        shortOutputDate += daysLeft
            ? daysLeft + ' Day' + (daysLeft !== 1 ? 's ' : ' ') + ' and '
            : '';
        shortOutputDate += hoursLeft + ' Hour' + (hoursLeft !== 1 ? 's ' : ' ');

        shortMinuteDate += minsLeft + ' Minute' + (minsLeft !== 1 ? 's ' : ' ');

        shortMinuteDate += secsLeft + ' Second' + (secsLeft !== 1 ? 's ' : ' ');

        shortCounter.forEach(function (el, index) {
            if(el.classList.contains('short-counter--minutes')) {
                el.innerHTML = shortMinuteDate;
            } else {
                el.innerHTML = shortOutputDate;
            }
        });
    }, 1000);
}

function getCurrentDay() {
    var now = new Date();
    var thisDay = now.getDate();
    var dayOutput = document.querySelectorAll('.output-day');

    dayOutput.forEach(function(el, index){
        el.textContent = thisDay + nth(thisDay);
    });
}

function getCurrentMonth() {
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now       = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    var monthOutput = document.querySelectorAll('.output-month');

    monthOutput.forEach(function(el, index){
        el.textContent = thisMonth;
    });
}

function getCurrentYear() {
    var now       = new Date();
    var thisYear = now.getFullYear(); 
    var yearOutput = document.querySelectorAll('.output-year');

    yearOutput.forEach(function(el, index){
        el.textContent = thisYear;
    });
}

function getDayofMonth() {
    var now = new Date();
    var thisDay = now.getDate();
    var thisMonth = now.getMonth();
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var thisMonth = months[thisMonth];
    var dayOutput = document.querySelectorAll('.output-day-of-month');

    dayOutput.forEach(function(el, index){
        el.textContent = thisDay + nth(thisDay) + " of " + thisMonth;
    });
}

function nth(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}

// Get the next saturday after today, and if it is already saturday, get the following sunday.
function nextSummitDate() {

    // If we are on the don't wait page - get the selected date passed through the query string as base64
    const urlParams = new URLSearchParams(window.location.search);
    let selectedSummitDate = urlParams.get('da');
    if (selectedSummitDate) {
        return new Date(atob(selectedSummitDate));
    } else {
        var today = new Date();

        if(everGreen) {
            // Get the next 15 minute slot
            var minutes = 15;
            for(var i = 0; i <= 60; i += 15) {
               if(i > today.getMinutes()) {
                minutes = i;
                break;
               }
            }
            return new Date(today.getTime() + (minutes - today.getMinutes()) * 60 * 1000);
        }

        var nextSummitDay = new Date(
            today.getTime() + (6 - today.getDay()) * 24 * 60 * 60 * 1000
        );

        if (today.getDay() == 6) {
            nextSummitDay = new Date(
                today.getTime() + (7 - today.getDay()) * 24 * 60 * 60 * 1000
            );
        }
        nextSummitDay.setHours(10,0,0);

        return nextSummitDay;
    }

}

function getSummitDayOfWeek() {
    var nextSummitDay = nextSummitDate();
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var day = days[nextSummitDay.getDay()];
    var output = document.querySelectorAll('.summit-weekday');

    output.forEach(function(el, index){
        el.textContent = day;
    });
}

function getSummitDay() {
    var nextSummitDay = nextSummitDate();
    var day = nextSummitDay.getDate();
    var output = document.querySelectorAll('.summit-day-of');
    var output2 = document.querySelectorAll('.summit-day');

    output.forEach(function(el, index){
        el.textContent = day + nth(day) + ' of ';
    });

    output2.forEach(function (el, index) {
        el.textContent = day + nth(day);
    });
}

function getSummitMonth() {
    var nextSummitDay = nextSummitDate();
    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var month = months[nextSummitDay.getMonth()];
    var output = document.querySelectorAll('.summit-month');

    output.forEach(function(el, index){
        el.textContent = month;
    });
}

function getSummitYear() {
    var nextSummitDay = nextSummitDate();
    var year = nextSummitDay.getFullYear();
    var output = document.querySelectorAll('.summit-year');

    output.forEach(function(el, index){
        el.textContent = year;
    });
}