if (window.location.pathname == '/dont-wait/') {

    window.contentLoaded(window, function (e) {
        embedCalendarLinks();
    }); 
    function generateCalendarLinks() {
        const urlParams = new URLSearchParams(window.location.search);
        let summitLiveUrl = atob(urlParams.get('live'));

        let eventStart = new Date(window.nextSummitDate());
        let eventEnd = new Date(window.nextSummitDate()).addHours(3);
        console.log(eventStart);

        let eventStartGCalFormat = eventStart.toISOString().replaceAll('-', '').replaceAll(':', '').split('.')[0] + 'Z';
        let eventEndGCalFormat = eventEnd.toISOString().replaceAll('-', '').replaceAll(':', '').split('.')[0] + 'Z';
        let eventStartOutlookFormat = eventStart.toISOString().replace('.000Z','+00:00');
        let eventEndOutlookFormat = eventEnd.toISOString().replace('.000Z','+00:00');


        let eventTitle = 'Sell Like Crazy Summit';
        let eventDescription = 'Don\'t miss this exclusive live event where Sabri Suby will be teaching you how to build a sales funnel that will make you rich. Join us on the day at '+ summitLiveUrl;

        let eventLinkGoogle = 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=' + encodeURIComponent(eventStartGCalFormat + '/' + encodeURIComponent(eventEndGCalFormat)) + '&details=' + encodeURIComponent(eventDescription) + '&location=Online&text=' + eventTitle;
        let eventLinkOutlook = 'https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body='+ encodeURIComponent(eventDescription) + '&enddt=' + encodeURIComponent(eventEndOutlookFormat) + '&location=Online&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=' + encodeURIComponent(eventStartOutlookFormat) + '&subject=' + encodeURIComponent(eventTitle);
        let eventLinkOffice = 'https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=' + encodeURIComponent(eventDescription) + '&enddt=' + encodeURIComponent(eventEndOutlookFormat) + '&location=Online&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=' + encodeURIComponent(eventStartOutlookFormat) + '&subject='+encodeURIComponent(eventTitle);
        let eventLinkYahoo = 'https://calendar.yahoo.com/?desc=' + encodeURIComponent(eventDescription) + '&dur=&et=' + encodeURIComponent(eventEndGCalFormat) + '&in_loc=Online&st=' + encodeURIComponent(eventStartGCalFormat) + '&title=' + encodeURIComponent(eventTitle) + '&v=60';

        return {
            google: eventLinkGoogle,
            yahoo: eventLinkYahoo,
            outlook: eventLinkOutlook,
            office: eventLinkOffice
        }
    }
    function embedCalendarLinks() {
        let calendarLinks = generateCalendarLinks();

        Object.entries(calendarLinks).forEach(key => {
            document.querySelector('a[data-calendar="' + key[0] + '"]').href = key[1];
        });
    }

}